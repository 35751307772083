// https://stackoverflow.com/questions/37482366/is-it-safe-to-expose-firebase-apikey-to-the-public
const config = {
    apiKey: "AIzaSyDrjcf8G_KlYoNqmms7pwPZmGYGIk0uUds",
    authDomain: "nfl-table.firebaseapp.com",
    projectId: "nfl-table",
    storageBucket: "nfl-table.appspot.com",
    messagingSenderId: "290686633807",
    appId: "1:290686633807:web:6a9f51ae91451dac2ea73a",
    measurementId: "G-7HEN34YMG0"
};

export default config